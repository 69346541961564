@media screen and ( max-width:  768px) {
    .m_hide {
        display: none; }
    .sponsorship-header h1 {
        font-size: 32px; }
    .contact_item {
        margin-right: 0; }
    .pricing_text,.pricing_img,.educator_item,.educator_item:first-child {
        width: 100%; }
    .contact-section_image {
        margin-left: 0; }
    .header_content {
        &.active {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            display: block;
            z-index: 55;
            background-image: url('../images/dest/mobile_menu_bg.jpg');
            background-size: cover;
            background-position: center;
            .header_nav_list {
                margin-top: 100px;
                display: block;
                text-align: center; }
            .header_logo {
                display: inline-block;
                margin-top: 25px; }
            .hamburger {
                position: absolute;
                top: 25px;
                right: 15px;
                margin-right: 0; }
            .header_actions {
                display: flex;
                margin-top: 45px;
                justify-content: center; }
            #close {
                display: block; }
            #open {
                display: none; } } }
    .hero_subheader {
        font-size: 12px; }
    .hero_header {
        font-size: 36px; }
    .hero_logo {
        display: block; }
    .hero_descr {
        font-size: 14px; }
    .header_logo {
        position: relative;
        z-index: 555; }
    .hero {
        min-height: 70vh; }
    .main_content {
        background-image: none;
        padding: 0 10px; }
    .hero {
        background-image: url('../images/dest/mobile_hero_bg.jpg');
        background-size: cover;
        background-position: center;
        &:hover {
            background-size: cover; }
        .float_icon {
            display: none; } }
    .header_logo {
        margin-left: 15px;
        margin-right: 0; }
    .study_in_system-section_image {
        margin-bottom: 30px; }
    .study_in_system_descr {
        margin: 0 auto;
        text-align: center;
        width: 90%; }
    .section_name {
        font-size: 18px;
        text-align: left;
        margin-top: 30px; }
    .section_header {
        font-size: 36px;
        text-align: left; }
    .page_wrapp.home {
        background-image: url('../images/dest/line_mob.svg');
        background-size: 100% auto;
        background-position: center top; }
    .about_item {
        margin: 0 auto;
        margin-bottom: 25px;
        .about_item_header {
            font-size: 18px; }
        p {
            font-size: 14px; } }
    .lessons {
        text-align: center;
        padding-bottom: 40px; }
    .lesson_item {
        margin: 0 auto;
        text-align: left;
        margin-bottom: 30px;
        &:last-child {
            margin-right: auto; } }
    .contact {
        flex-wrap: wrap-reverse; }
    .contac_descr {
        width: 90%;
        margin: 0 auto;
        margin-top: 15px; }
    .footer {
        padding: 10px 15px;
        margin-top: 75px;
        text-align: center;
        .row {
            flex-wrap: wrap-reverse;
            justify-content: center; } }
    .footer_nav {
        margin-bottom: 15px; }
    .hamburger {
        display: block;
        margin-right: 15px;
        margin-left: auto;
        width: 24px;
        height: 24px; }
    .header_nav_list {
        display: none; }
    .header_link {
        margin-bottom: 15px;
        display: inline-block; }
    #close {
        display: none; }
    .actionButtons {
        text-align: left; }
    .courseFormName {
        margin: 25px 0; }
    .editCourseForm {
        margin-top: 25px; }
    .edit_lesson_title {
        font-size: 32px;
        width: 100%; }
    .tab_switcher {
        margin-right: 20px;
        padding: 10px 20px; }
    .results-wrapper-small {
        display: none; }
    .sec-category-content .results-wrapper-small {
        padding: 0; }
    .sec-category-content .results-wrapper-small .cat-item {
        flex: 0 0 100%;
        padding-right: 0; }
    .panel-catresults {
        padding: 10px 15px;
        box-sizing: border-box;
        background-color: #fff;
        .cat-title {
            font-size: 24px;
            font-weight: 500;
            color: #282c4f;
            letter-spacing: 2px;
            text-transform: uppercase; }
        .cat-section {
            font-size: 14px;
            font-weight: 500;
            color: $accent;
            margin-top: 16px;
            margin-bottom: 0; }
        .cat-description {
            font-size: 16px;
            color: #414141; }
        .cat-result {
            font-size: 16px;
            color: #414141; }
        .admin-consultation-action-container {
            margin: 16px 0; }
        .cat-btn-details {
            display: inline-block;
            padding: 8px 42px;
            background-color: $accent;
            border-radius: 3px;
            margin-top: 40px;
            color: #fff;
            width: 100%;
            box-sizing: border-box;
            text-align: center; } }
    .course-progress-item {
        margin: 0 auto;
        margin-bottom: 10px; }
    .course-progress-lessons-result {
        margin-right: 0; }
    .lesson-details {
        padding: 0; }
    .letter-container {
        font-weight: 300;
        font-size: 42px;
        text-align: center;
        width: 40px;
        font-family: Nunito, sans-serif;
        opacity: 0.22;
        color: $accent;
        p {
            margin: 0; } }
    .letter-box {
        display: flex;
        align-items: flex-start; }
    .cat-wrapper {
        flex-grow: 1; }
    .single-category {
        padding: 5px 16px;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        text-decoration: none;
        border-radius: 18px;
        outline: none;
        display: block; }
    .lesson-details-lesson-description {
        font-size: 24px; } }
@media screen and ( min-width:  768px) {
    .d_hide {
        display: none; } }
@media screen and ( max-width:  320px) {
    .lessonSlideWrapper {
        width: 310px;
        min-width: 100px; } }
